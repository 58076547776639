import type { AppProps } from 'next/app'
import { HeaderContextProvider } from 'ui/context/header'
import { appWithTranslation } from 'next-i18next'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { IntercomProvider } from 'react-use-intercom'
import Head from 'next/head'
import 'ui/css/global.css'
import AccessibeScript from 'ui/components/organisms/c-accessibe-script'
import OneTrustScript from 'ui/components/organisms/c-one-trust'
import { useSiteInfo } from 'shared'
import { useRouter } from 'next/router'

declare global {
  export interface Window {
    analytics: any
    OneTrust: any
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const siteInfo = useSiteInfo()

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      window.OneTrust?.initializeCookiePolicyHtml()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const gtmId1 = process.env.NEXT_PUBLIC_QUIZ_GMTD_ID_1
      const gtmId2 = process.env.NEXT_PUBLIC_QUIZ_GMTD_ID_2
      gtmId1 && TagManager.initialize({ gtmId: gtmId1 })
      gtmId2 && TagManager.initialize({ gtmId: gtmId2 })
    }
  }, [])

  return (
    <IntercomProvider
      appId={siteInfo.isUS ? process.env.NEXT_PUBLIC_INTERCOM_ID ?? '' : ''}
      autoBoot
    >
      <HeaderContextProvider>
        <Head>
          <link rel="icon" type="image/x-icon" href="/visian-icon.svg"></link>
        </Head>
        <Component {...pageProps} />
        {(siteInfo.isUS || siteInfo.isCA) && <AccessibeScript />}
        <OneTrustScript />
      </HeaderContextProvider>
    </IntercomProvider>
  )
}

export default appWithTranslation(App)
